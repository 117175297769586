.partners{
    .custom-container{

        .partners-caption{
            margin-bottom: 2.66667rem;

            p{
                font-size: 1rem;
            }
        }

        .partners-title{
            margin-bottom: 4.33333rem;

            h1{
                font-size: 4rem;
                line-height: 0.9;
                text-transform: uppercase;
            }
        }

        .partners-main{
            width: 100%;
            word-break: normal;
            flex-wrap: wrap;
            display: flex;
            h1{
                font-size: 7rem;
                color: rgba(0,0,0,0.4);
                text-transform: uppercase;
                line-height: 0.9;

                &:hover{
                    color: rgba(0,0,0,1);
                }
            }

            .partner-img{
                padding: 2rem 4rem;
                img{
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 480px){
    .partners .custom-container .partners-main .partner-img{
        padding: 1rem 2rem;
    }

    .partners .custom-container .partners-title h1{
        font-size: 14.7vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px){
    .partners .custom-container .partners-main .partner-img{
        padding: 1rem 3rem;
    }

    .partners .custom-container .partners-title h1{
        font-size: 13vw;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px){
    .partners .custom-container .partners-main .partner-img{
        padding: 1rem 2rem;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px){
    .partners .custom-container .partners-main .partner-img{
        padding: 1rem 2rem;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px){
    .partners .custom-container .partners-main .partner-img{
        padding: 1rem 2rem;
    }
}