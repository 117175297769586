.benefits{
    margin-bottom: 6.66667rem;
    .benefits-wrapper{
        background-color: white;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding: 1rem 3.33333rem 4rem 3.33333rem;
        display: flex;
        flex-direction: column;

        p{
            margin-bottom: 0px!important;
            font-size: 1.46667rem;
            line-height: 1.2;
            font-weight: 500;
        }

        .tick{
            margin-right: 10px;
            svg{
                stroke: transparent;
                fill: green;
                width: 30px;
                height: 30px;
            }
        }

        .ben-item{
            display: flex;
            margin: 5px 2rem;
            padding: 2rem 0rem;
            border-bottom:1px solid #dddada;
        }
    }
}


@media only screen and (max-width: 480px){
    .benefits .benefits-wrapper{
        width: 100%;
        padding: 0px 0px 0px 0px;
    }

    
}

@media only screen and (min-width: 481px) and (max-width: 768px){
    .benefits .benefits-wrapper{
        width: 100%;
        padding: 0px 0px 0px 0px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1200px){
    
}

@media only screen and (min-width: 1201px) and (max-width: 1400px){

}