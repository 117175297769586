.footer{
    background-color: #221E1E!important;
    padding-top: 5.33rem;

    p,h1,h2,h3,h4,li,h1{
        color: white;
    }
    .newsletter-wrapper{
        p{
            margin-bottom: 2.66667rem;
        }
        h1{
            font-size: 3.3333rem;
            line-height: 1;
            margin-bottom: 25px;
        }

        input{
            padding: 0 1.33333rem;
            background-color: #fff;
            border-radius: 7px;
            height: 3.73333rem;
        }

        button{
            padding: 0 1.33333rem;
            background-color: #fff;
            border-radius: 7px;
            height: 3.73333rem;
        }
    }

    .links-wrapper{
        .top-links-list{
            display: flex;
            justify-content: start;
            margin-bottom: 6.666rem;
            p{
                margin-right: 2.3333rem;
            }
        }
    }

    .tnc-links{
        width: 60%;
        
        .link-title{
            color: #a6a5a5;
            border-bottom: 1px solid #a6a5a5;
            margin-bottom: 1.33333rem;
            padding-bottom: .66667rem;
        }

        p{
            font-size: 1.46667rem;
            margin-bottom: .8rem;
            line-height: 1.1;
        }
    }

    .industries-list{
        width: 40%;
        padding-left: 30px;
        .link-title{
            color: #a6a5a5;
            border-bottom: 1px solid #a6a5a5;
            margin-bottom: 1.33333rem;
            padding-bottom: .66667rem;
        }

        p{
            font-size: 1.46667rem;
            margin-bottom: .8rem;
            line-height: 1.1;
        }
    }

    .last-row{
        margin-top: 14rem;
        padding-bottom: 30px;
    }

    .contact-list{
        display: flex;
        justify-content: space-between;
        p {
            margin: 0 30px 0 0;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    .footer .links-wrapper .top-links-list{
        flex-wrap: wrap;
        margin-bottom: 3rem;
        margin-top: 2rem;
    }

    .footer .newsletter-wrapper h1{
        font-size: 14.7vw;
    }

    .footer .contact-list{
        flex-wrap: wrap;
    }

    .footer .last-row{
        margin-top: 6rem;
        padding-bottom: 0px;
    }

    p{
        font-size: 1rem!important;
        line-height: 1.2!important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px){
    .footer .links-wrapper .top-links-list{
        flex-wrap: wrap;
        margin-bottom: 3rem;
        margin-top: 2rem;
    }

    .footer .newsletter-wrapper h1{
        font-size: 14.7vw;
    }

    .footer .contact-list{
        flex-wrap: wrap;
    }

    .footer .last-row{
        margin-top: 6rem;
        padding-bottom: 0px;
    }

    p{
        font-size: 1rem!important;
        line-height: 1.2!important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px){
    .footer .links-wrapper .top-links-list{
        flex-wrap: wrap;
        margin-bottom: 3rem;
        margin-top: 2rem;
    }

    .footer .newsletter-wrapper h1{
        font-size: 13vw;
    }

    .footer .contact-list{
        flex-wrap: wrap;
    }

    .footer .last-row{
        margin-top: 6rem;
        padding-bottom: 0px;
    }

    p{
        font-size: 1rem!important;
        // line-height: 1.2!important;
    }
}