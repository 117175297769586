#draggable{
  width: 100%;
  overflow-x: hidden;
  background-color: #F6F9FB;
  position: relative;
  margin-bottom: 9.3333rem;

  .svg-arrow{
    position: absolute;
    top:5%;
    width: auto;
    height: 200px;
    text-align: center;
    left: 50%;
    path{
      transform: rotateZ(180deg);
      transform-origin: center center;
      fill: #0F2043;
    }
  }
}

.dragger-container{
    padding-left: 10rem;
    padding-right: 10rem;
    margin-top: 18rem;
    overflow: hidden;
    position: relative;

    .dragger-caption{
        p{
            font-size: 1rem;
        }

        margin-bottom: 2.66667rem;
    }

    .dragger-title{
      position: relative;
        h1{
            font-size: 4rem;
            line-height: 0.9;
        }

        margin-bottom: 4.33333rem;
    }

    .drag-slider{
      display: flex;
      flex-direction: row;  
      will-change: transform;
      position: relative;
      width: auto;
      // align-items: flex-end;
      // justify-content: space-between;

      .item{
        width: 50%;
        margin-right: 10%;
      }
    }

    
    .slides-container {
      position: relative;
      overflow: hidden;  
      display: flex;
      flex: 1;
    }
    
    .slide {
      position: absolute;
      font-size: 90px;
      font-weight: 700;
      color: rgba(255,255,255,0.9);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      
      width: calc(100% / 3);
      width: 50%;
      margin-right: 10%
    }
    
    .slides-inner {
      position: relative;
      height: 100%;
      width: 100%;  
      overflow: hidden;
    }
    
    
      
}


.items {
 
  width:100%!important;
  /*border:1px solid white;*/
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  // user-select: none;
  cursor: pointer;
  transition: all 0.2s;
  will-change: transform;
  position: relative;
  transition: all .5s ease;

  img{
    pointer-events: none;
  }
}

.items:nth-child(2){
    width: 200px;
}

.items.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  // transform: scale(0.6);
}

.item.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  // transform: scale(0.8);
  // transition: all .4s ease;
}


.carousel-button-group{
  position: absolute;
  right: 10rem;
  top: 15%;
  display: flex;
  
  div{
    

    button{
      background-color: transparent;
      box-shadow: transparent;
      border-radius: 50%;
      border: none;
      margin-left: 20px;

      svg{
        width: 3.5rem;
        height: 3.5rem;
        stroke: #e7e7e7;
      }
    }
  }
}

.react-multi-carousel-list{
  overflow:visible!important;

  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
  
}

.my-own-custom-container{
  img{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  p{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  h3{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}


@media only screen and(max-width: 480px){

  #draggable{
    margin-bottom: 6.666rem;
  }

  #draggable .svg-arrow{
    display: none;
  }

  .dragger-container{
    padding: 0px 20px 60px 20px;
    margin-top: 100px;
  }

  .dragger-container .dragger-title h1{
    font-size: 14.7vw;
  }

  .carousel-button-group{
    top: 95%;
    left: 0%;
  }

  .carousel-button-group div button{
    margin: 0px;
  }
}

@media only screen and(min-width: 481px) and (max-width: 1024px){

  #draggable .svg-arrow{
    display: none;
  }


  .dragger-container{
    padding: 0px 20px 60px 20px;
    margin-top: 100px;
  }

  .dragger-container .dragger-title h1{
    font-size: 13vw;
  }

  .carousel-button-group{
    top: 95%;
    left: 0%;
  }

  .carousel-button-group div button{
    margin: 0px;
  }
}

@media only screen and(min-width: 1025px) and (max-width: 1200px){
  #draggable .svg-arrow{
    display: none;
  }


  .dragger-container{
    padding: 0px 20px 60px 20px;
    margin-top: 100px;
  }

  .dragger-container .dragger-title h1{
    font-size: 9vw;
  }

  .carousel-button-group{
    top: 95%;
    left: 0%;
  }

  .carousel-button-group div button{
    margin: 0px;
  }
}