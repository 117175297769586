@font-face {
    font-family: 'Tungsten';
    src: url(./fonts/EM-Tungsten/EM-Tungsten/Tungsten-Semibold.otf) format('opentype');
}

@font-face {
    font-family: 'Montreal-Regular';
    src: url(./fonts/Neue-montreal/NeueMontreal-Regular.otf) format('opentype');
}

body{
    background-color: #F6F9FB!important;
    // height: 100vh;
}

a{
    &:hover{
        color: #0F2043!important
    }
}

p{
    font-family: 'Montreal-Regular'!important;
    &::selection{
        background: #38E2B1;
    }
}

h2,h3,h4,h5,h6{
    &::selection{
        background: #38E2B1;
    }
}

h1{
    font-family: 'Tungsten'!important;
    &::selection{
        background: #38E2B1;
    }
}

.custom-container{
    padding-left: 10rem;
    padding-right: 10rem;
    width: 100%;
    margin: auto;
    position: relative;
}

.App{
    overflow-x: hidden;
}

a{
    text-decoration: none!important;
}

@media only screen and(max-width: 480px){
    body{
        overflow-x: hidden!important;
    }

    .custom-container{
        padding-left: 20px;
        padding-right: 20px;
    }
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px){
    .custom-container{
        padding-left: 20px;
        padding-right: 20px;
    }
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px){
    .custom-container{
        padding-left: 20px;
        padding-right: 20px;
    }
  }