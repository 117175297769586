#text-scroller{
    background-color: #F6F9FB;
    position: relative;

    .svg-plus{
        position: absolute;
        left: 0%;
        top: 10%;
        width: 150px;
        height: 150px;
        path{
            fill: #0F2043;
            width: 100%!important
        }
    }
    .text-inner__wrapper{
        width: 100%;
        position: relative;
        margin-bottom: 9.3333333rem;
        .vdo-btn{
            position: absolute;
            transform: translate(-50%,-50%);
            top: 50%;
            left: 50%;
            z-index: 5;
            
            button{
                border: 0px;
                background-color: transparent;
                // border: 1px solid #0F2043;
                padding: 15px 30px 20px 30px;
                border-radius: 35px;
                box-shadow: 0 0 50px 0 rgba(0,0,0,.1);
                color: #221e1e;
            }
        }

        .understand{
            display: flex;
            flex-direction: row;
            h1{
                font-size: 17vw;
                line-height: 1;
            }
        }
        .svg-wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            
            svg{
                width: 220px;
            }
                rect{
                    fill: #FFE900;
                }
          
        }

        .advice{
            display: flex;
            flex-direction: row;
            text-align: right;
            justify-content: end;

            h1{
                font-size: 17vw;
                line-height: 1;
            }
        }

        .shape{
            display: flex;
            flex-direction: row;
            padding-left: 180px;
            position: relative;
            h1{
                font-size: 17vw;
                line-height: 1;
            }

            h4{
                font-weight: 600;
                font-size: 28px;
                padding-bottom: 15px;
                margin-top: 30px;
            }

            p{
                max-width: 45%;
                padding-bottom: 20px;
            }
        }
    }
}

@media only screen and (max-width: 480px){
    #text-scroller .text-inner__wrapper{
        padding-top: 0px;
    }

    #text-scroller h1{
        font-size: 16vw!important;
    }

    #text-scroller .text-inner__wrapper .shape h4{
        font-size: 1.4667rem;
    }

    #text-scroller .text-inner__wrapper .svg-wrapper svg{
        width: 80px;
        margin-left: 30px;
    }

    #text-scroller .svg-plus{
        width: 100%;
        position: relative;

        path{
            position: absolute;
            top: 0;
        }
    }

    #text-scroller .text-inner__wrapper .shape{
        padding-left: 0px;
        padding-right: 0px;
    }

    #text-scroller .text-inner__wrapper .shape p{
        max-width: 100%;
        font-size: .9rem;
    }

    #text-scroller .svg-plus{
        height: 50px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px){
    #text-scroller .text-inner__wrapper .shape p{
        max-width: 50%;
    }

    #text-scroller .text-inner__wrapper .svg-wrapper svg{
        width: 100px;
    }

    #text-scroller .svg-plus{
        width: 70px;
        height: 70px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px){
    #text-scroller .text-inner__wrapper .shape p{
        max-width: 50%;
    }

    #text-scroller .text-inner__wrapper .svg-wrapper svg{
        width: 120px;
    }
    
    #text-scroller .svg-plus{
        width: 100px;
        height: 100px;
        left: 7%;
    }
}