.nav-cta{
    display: flex;
    align-items: center;
    .talk{
        position: relative;
        background-color: #fff!important;
        color: #000!important;
        box-shadow: none;
        border: none;
        border-radius: 7px;
        padding: 15px 1.33333rem!important;
        width: 12rem;
        box-shadow: 0 0 50px 0 rgba(0,0,0,.1);
        .cta-text{
            position: relative;
            color: #000;
            z-index: 5;
        }

        .cta-icon{
            color: #000;
        }

        &::before{
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: scaleX(0);
            transition: transform .6s cubic-bezier(.075,.82,.165,1);
            transform-origin: center left;
            border-radius: 7px;
            background-color: #38E2B1;
        }
    }

    .talk:hover::before{
        transform: scaleX(1);
        color: white!important;
    }    
    
    .talk:hover > .cta-text{
        color: white;
    }
}

@media only screen and (max-width: 480px){
    .nav-cta .talk {
        width: 9rem;
        padding: 12px 1.33333rem !important;
      }
}

@media only screen and (min-width: 481px) and (max-width: 768px){
    .nav-cta .talk {
        width: 9rem;
        padding: 12px 1.33333rem !important;
      }
}

@media only screen and (min-width: 769px) and (max-width: 1024px){
    .nav-cta .talk {
        width: 9rem;
        padding: 12px 1.33333rem !important;
      }
}