.simple-accordion{
    padding-bottom: 6.66667rem;
    .MuiPaper-root{
        .MuiTypography-root{
            font-size: 1.46667rem;
            line-height: 1.2;
            font-weight: 500;
        }
        
        .MuiButtonBase-root{
            background: #fff;
            flex-direction: row!important;
            justify-content: space-between!important;
            

            .MuiAccordionSummary-content{
                border-bottom: 1px solid #dddada;
            }
            .MuiAccordionSummary-content {
                padding: 2rem 0;
            }

            .MuiAccordionSummary-content{
                margin-left: 0px!important;
            }
        }
    }

    .accordion-main{
        padding: 3.3333rem 3.33333rem 4rem 3.33333rem;
        background: #fff;

        .css-1fjvggn-MuiPaper-root-MuiAccordion-root{
            border: 0px;
        }

        .css-ta84vm-MuiAccordionDetails-root{
            border-top: 0px;
        }
    }

    .acc-card{
        width: 100%;
        height: 500px;
        position: relative;

        .acc-card-content{
            display: flex;
            flex-direction: column;
            position: absolute;  
            width: 80%;
            bottom: 10%;
            left: 0;

            p{
                margin-bottom: 40px;
            }
        }

        .acc-card-img{
            width: 100%;
            height: 500px;
            overflow: hidden;
            img{
                width: 100%;
            }
        }
    }

    .testimonialBy{
        margin-top: 1rem;
        font-size: 1rem;
        font-weight: 600;
        color: rgba(0,0,0,0.5);
    }
}


@media only screen and (max-width: 480px){

    .simple-accordion{
        padding-bottom: 0px;
    }
    .simple-accordion .accordion-row{
        flex-direction: column;
        padding-left: 0px;
        
    }

    .simple-accordion .accordion-row .left-half{
        width: 100%;
        padding-left: 20px;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .simple-accordion .accordion-row .left-half h1{
        font-size: 14.7vw;
    }

    .simple-accordion .accordion-row .right-half{
        width: 100%;
        height: 400px;
    }

    .simple-accordion .accordion-main{
        padding: 60px 20px;
    }

    .simple-accordion .MuiPaper-root .MuiTypography-root{
        font-size: 1.3rem;
    }

    .simple-accordion .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content{
        padding: 1.3rem 0;
    }

    .simple-accordion .acc-card .acc-card-content{
        position: relative;
    }

    .simple-accordion .acc-card .acc-card-img{
        height: auto;
    }

    .simple-accordion .acc-card{
        height: auto;
    }

    .css-9l5vo-MuiCollapse-wrapperInner{
        margin-top: 20px;
    }

    .simple-accordion .acc-card .acc-card-content p{
        margin-bottom: 20px;
    }
}   