.contact-form{
    background-color: #E1EFF6;

    .contact-head{
        padding-bottom: 6.6666rem;
    }

    .form-container{
        padding-bottom: 6.6666rem;
        padding-top: 6.6666rem;
    }

    .form-main{
        background: white;
        width: 70%;
        margin: 0px auto;
        padding: 4rem;
    
        h1{
            margin-bottom: 3rem;
        }
    
        .form-row{
            margin-bottom: 3.3333rem;
    
            input{
                width: 100%;
            }
    
            .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
                width: 100%!important;
            }
        }
    
        .css-sghohy-MuiButtonBase-root-MuiButton-root{
            background-color: #0F2043;
        }
    }
}

.css-i44wyl{
    width: 100%;
}
