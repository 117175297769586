.card{
    // width: 100%;
    position: relative;
    border: none;
    background-color: #F6F9FB;
    margin-right: 9vw;

    .card-link{
        width: 100%;
        position: relative;

        .inner-wrapper{
            margin-bottom: 1.66667rem;
            position: relative;

            .card-btn{
                position: absolute;
                bottom: -1.86667rem;
                right: 1.73333rem;
                z-index: 2; 
            }
        }

        .card-title{
            h3{
                font-size: 1.46667rem;
                margin-bottom: .8rem;
                line-height: 1.27273;
                color: #221e1e!important;
                font-weight: 600;
            }
        }

        .card-content{
            max-width: 22.66667rem;
            p{
                color: #221e1e;
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }

    .image-wrapper{
        img{
            width: 100%;
        }
    }
}

@media only screen and (max-width: 480px){
    .card .card-link .card-content p{
        font-size: .9rem;
    }
}