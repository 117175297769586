.home-hero{
    background-color: #E1EFF6;
    width: 100%;
    height: 100vh;
    padding-top: 4.66667rem;
    overflow: hidden;
    position: relative;
    .svg-one{
        position: absolute;
        z-index: 2;
    }
    .custom-row{
        display: flex;
        flex-direction: row;
        position: relative;
        
        .hero-left__wrapper{
            width: 58%;
            position: relative;
            height: 100vh;
            img{
                position: absolute;
                z-index: 0;
                max-width: 100%;
                width: auto;
                max-height: 85%;
                bottom: 50px;
            }
        }
    
        .home-hero__content{
            width: 42%;
            overflow: hidden;
            position: relative;
            height: 100vh;
            background-color: #E1EFF6;

            .hero-content__wrapper{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                

                .hero-inner-content{
                    display: flex;
                    align-items: flex-end;

                    h1{
                        font-size: 7.5vw;
                        line-height: 0.9;
                        text-transform: uppercase;
                    }

                    .hero-tagline{
                        width: 50%;
                        padding-left: 50px;
                        // text-align: justify;
                    }
        
                }


                .buttons-wrapper{
                    display: flex;
                    margin-bottom: 100px;
                    margin-top: 50px;

                    .nav-cta:first-child{
                        margin-right: 50px;
                    }

                }

                .nav-cta{
                    display: flex;
                    align-items: center;
                    .talk{
                        position: relative;
                        background-color: #fff!important;
                        color: #000!important;
                        box-shadow: none;
                        border: none;
                        border-radius: 7px;
                        padding: 15px 1.33333rem!important;
                        width: 12rem;
                        box-shadow: 0 0 50px 0 rgba(0,0,0,.1);
                        .cta-text{
                            position: relative;
                            color: #000;
                            z-index: 5;
                        }
            
                        .cta-icon{
                            color: #000;
                        }
        
                        &::before{
                            content: "";
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            transform: scaleX(0);
                            transition: transform .6s cubic-bezier(.075,.82,.165,1);
                            transform-origin: center left;
                            border-radius: 7px;
                            background-color: #38E2B1;
                        }
                    }
        
                    .talk:hover::before{
                        transform: scaleX(1);
                        color: white!important;
                    }    
                    
                    .talk:hover > .cta-text{
                        color: white;
                    }
                }
            }
        

        }
    }
    
}

@media only screen and (max-width: 480px){
    .home-hero .custom-row{
        display: flex;
        flex-direction: column-reverse;
    }

    .home-hero .custom-row .home-hero__content{
        width: 100%;
        height: 40vh;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper{
        top: 5%;
        transform: translateY(0%);
        width: 100%;
        left: 2%;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .hero-inner-content{
        width: 100%;
        align-items:flex-start;
        display: flex;
        flex-direction: row;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .hero-inner-content h1{
        font-size: 16vw;
        width: 50%;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .nav-cta .talk{
        width: 8rem;
        padding: 12px 1.33333rem !important;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .buttons-wrapper .nav-cta:first-child{
        margin-bottom: 20px;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .hero-inner-content .hero-tagline{
        width: 100%;
        font-size: 16px;
        display: none;
       
    }

    .home-hero .custom-row .hero-left__wrapper{
        height: 50vh;
        width: 100%;
    }

    .home-hero .custom-row .hero-left__wrapper img{
        bottom: 0px;
    }

    .home-hero .svg-one{
        width: 100%;
        bottom: -100px;
        z-index: 0;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .buttons-wrapper{
        margin-bottom: 0px;
        flex-direction: column;
        padding-left: 0%;
        margin-top: 0px
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px){
    .home-hero .custom-row{
        display: flex;
        flex-direction: column-reverse;
    }

    .home-hero .custom-row .home-hero__content{
        width: 100%;
        height: 40vh;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper{
        top: 5%;
        transform: translateY(0%);
        width: 100%;
        left: 2%;
        display: flex;
        flex-direction: row;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .hero-inner-content{
        width: 50%;
        align-items:flex-start;
        display: flex;
        flex-direction: row;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .hero-inner-content h1{
        font-size: 80px;
        width: 100%;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .nav-cta .talk{
        width: 8rem;
        padding: 12px 1.33333rem !important;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .buttons-wrapper .nav-cta:first-child{
        margin-bottom: 10px;
        margin-right: 10px
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .hero-inner-content .hero-tagline{
        width: 100%;
        font-size: 16px;
        display: none;
       
    }

    .home-hero .custom-row .hero-left__wrapper{
        height: 55vh;
        width: 100%;
    }

    .home-hero .custom-row .hero-left__wrapper img{
        bottom: 0px;
    }

    .home-hero .svg-one{
        width: 100%;
        bottom: -100px;
        z-index: 0;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .buttons-wrapper{
        // margin-bottom: 0px;
        // flex-direction: column;
        // padding-left: 50%;
        // margin-top: -80px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 50%;
        margin-top: 0px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px){
    .home-hero .custom-row{
        display: flex;
        flex-direction: column-reverse;
    }

    .home-hero .custom-row .home-hero__content{
        width: 100%;
        height: 40vh;
    }

    // .home-hero .custom-row .home-hero__content .hero-content__wrapper .hero-inner-content .hero-tagline{
    //     padding-left: 0px;
    // }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper{
        top: 5%;
        transform: translateY(0%);
        width: 100%;
        left: 2%;
        display: flex;
        flex-direction: column;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .hero-inner-content{
        width: 60%;
        align-items:flex-start;
        display: flex;
        flex-direction: row;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .hero-inner-content .hero-tagline{
        align-self: end;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .hero-inner-content h1{
        font-size: 80px;
        width: fit-content;
    
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .nav-cta .talk{
        width: 8rem;
        padding: 12px 1.33333rem !important;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .buttons-wrapper .nav-cta:first-child{
        margin-bottom: 0px;
        margin-right: 10px
    }

    .home-hero .custom-row .hero-left__wrapper{
        height: 55vh;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .home-hero .custom-row .hero-left__wrapper img{
        bottom: 0px;
        max-height: 100%;
    }

    .home-hero .svg-one{
        width: 100%;
        bottom: -100px;
        z-index: 0;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .buttons-wrapper{
       
        margin-bottom: 0px;
        display: flex;
        width: 50%;
        margin-top: 0px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px){
    .home-hero .custom-row .hero-left__wrapper{
        position: relative;
        overflow: hidden;
    }

    .home-hero .svg-one{
        width: 55%;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .hero-inner-content h1{
        font-size: 9vw;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .hero-inner-content .hero-tagline{
        padding-left: 20px;
    }
    .home-hero .custom-row .home-hero__content .hero-content__wrapper .buttons-wrapper .nav-cta:first-child{
        margin-right: 25px;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .nav-cta .talk{
        width: 10rem;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px){
    .home-hero .custom-row .hero-left__wrapper{
        position: relative;
        overflow: hidden;
    }

    .home-hero .svg-one{
        width: 50%;
    }

    .home-hero .custom-row .home-hero__content{
        width: 48%;
    }

    .home-hero .custom-row .hero-left__wrapper{
        width: 52%;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .hero-inner-content h1{
        font-size: 9vw;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .hero-inner-content .hero-tagline{
        padding-left: 20px;
    }
    .home-hero .custom-row .home-hero__content .hero-content__wrapper .buttons-wrapper .nav-cta:first-child{
        margin-right: 25px;
    }

    .home-hero .custom-row .home-hero__content .hero-content__wrapper .nav-cta .talk{
        width: 10rem;
    }
}