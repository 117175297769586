#client-card{
    margin-right: 3.8vw;
    text-align: center;

    *{
        user-select: none;
    }
}
.client-card{
    position: relative;
    background-color: #fff;
    border-radius: .53333rem;
    box-shadow: 0 2px 21px 0 rgba(0,0,0,.1);
    overflow: hidden;
    transform: translateZ(0);
    width: 100%;
    text-align: center;

    .company-logo{
        height: 16.33333rem;
        padding: 4rem 5rem;
        border-bottom: 1px solid #dddada;
    }

    .company-title{
        padding: 2rem 1.2rem;
        min-height: 7.33333rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}