#accordion{
    margin-bottom: 6.66667rem;
    width: 100%;
    background-color: #F6F9FB;

    .css-1mxs4hs{
        border: 0px!important;
    }

    .css-1fpbxjw{
        border: 0px!important;
    }
    .accordion-row{
        position: relative;
        display: flex;
        background-color: #221E1E;
        padding-left: 10rem;
        margin-bottom: -7.2rem;
        .left-half{
            width: 50%;
            padding-top: 9rem;
            padding-bottom: 16.66667rem;
            display: flex;
            
            svg{
                margin-left: 20px;
                path{
                    fill: #FFE900;
                }
            }
            h1{
                color: white;
                font-size: 9.33333rem;
                line-height: 1;
                margin-bottom: 3.33333rem;
            }
        }

        .right-half{
            width: 50%;
            background-image: url('https://iti.ca/themes/iti/assets/images/static/solutions-ti_bg.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }
    }

    .MuiPaper-root{
        .MuiTypography-root{
            font-size: 1.46667rem;
            line-height: 1.2;
            font-weight: 500;
        }
        
        .MuiButtonBase-root{
            background: #fff;
            flex-direction: row!important;
            justify-content: space-between!important;
            

            .MuiAccordionSummary-content{
                border-bottom: 1px solid #dddada;
            }
            .MuiAccordionSummary-content {
                padding: 2rem 0;
            }

            .MuiAccordionSummary-content{
                margin-left: 0px!important;
            }
        }
    }

    .accordion-main{
        padding: 1rem 3.33333rem 4rem 3.33333rem;
        background: #fff;

        .css-1fjvggn-MuiPaper-root-MuiAccordion-root{
            border: 0px;
        }

        .css-ta84vm-MuiAccordionDetails-root{
            border-top: 0px;
        }
    }

    .acc-card{
        width: 100%;
        height: 500px;
        position: relative;

        .acc-card-content{
            display: flex;
            flex-direction: column;
            position: absolute;  
            width: 80%;
            bottom: 10%;
            left: 0;

            p{
                margin-bottom: 40px;
            }
        }

        .acc-card-img{
            width: 100%;
            height: 500px;
            overflow: hidden;
            img{
                width: 100%;
            }
        }
    }
   
}

@media only screen and (max-width: 480px){

    #accordion{
        padding-bottom: 0px;
        margin-bottom: 6.666rem;
    }
    #accordion .accordion-row{
        flex-direction: column;
        padding-left: 0px;
        
    }

    #accordion .accordion-row .left-half{
        width: 100%;
        padding-left: 20px;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    #accordion .accordion-row .left-half h1{
        font-size: 14.7vw;
    }

    #accordion .accordion-row .right-half{
        width: 100%;
        height: 400px;
    }

    #accordion .accordion-main {
        padding: 0px 20px 20px;
    }

    #accordion .MuiPaper-root .MuiTypography-root{
        font-size: 1.4667rem;
    }

    #accordion .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content{
        padding: 1.3rem 0;
    }

    #accordion .acc-card .acc-card-content{
        position: relative;
    }

    #accordion .acc-card .acc-card-img{
        height: auto;
    }

    #accordion .acc-card{
        height: auto;
    }

    .css-9l5vo-MuiCollapse-wrapperInner{
        margin-top: 20px;
    }

    #accordion .acc-card .acc-card-content p{
        margin-bottom: 20px;
        font-size: 0.9rem;
    }

    #accordion .accordion-row .left-half svg{
        display: none;
    }
}   

@media only screen and (min-width: 481px) and (max-width: 768px){
    #accordion .accordion-row{
        padding-left: 20px;
    }

    #accordion .accordion-row .left-half h1{
        font-size: 13vw;
    }

    #accordion .acc-card .acc-card-content p{
        margin-bottom: 0px;
        padding-bottom: 20px;
    }
    #accordion .acc-card{
        height: auto;
    }

    #accordion .acc-card .acc-card-img{
        height: auto;
    }

    #accordion .acc-card .acc-card-content{
        position: relative;
        width: 100%;
        bottom: 0%;
    }

    #accordion .accordion-row .left-half svg{
        display: none;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px){
    #accordion .accordion-row{
        padding-left: 20px;
    }

    #accordion .acc-card{
        height: auto;
    }

    #accordion .acc-card .acc-card-img{
        height: auto;
    }

    #accordion .acc-card .acc-card-content p{
        margin-bottom: 0px;
        padding-bottom: 20px;
    }

    #accordion .accordion-row .left-half h1{
        font-size: 13vw;
    }

    #accordion .acc-card .acc-card-content{
        position: relative;
        width: 100%;
        bottom: 0px;
    }

    #accordion .accordion-row .left-half svg{
        display: none;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px){
    #accordion .accordion-row{
        padding-left: 20px;
    }

    #accordion .accordion-row .left-half h1{
        font-size: 9vw;
    }
}