.industry-hero{
    padding-top: 11.33333rem;
    margin-bottom: 4rem;
    .industry-hero__header{
        padding-left: 10rem;
        margin-bottom: 4.33333rem;
        .industry-hero__header-caption{
            height: 100%;
            display: flex;
            align-items: flex-end;
            p{
                font-size: 1rem;
            }
        }

        .industry-hero__header-title{
            h1{
                font-size: 9.333333rem;
                line-height: 0.9;
            }
        }
    }

    .industry-hero__body{
        padding-left: 10rem;
        padding-right: 0px;

        .industry-hero__parallax{
            max-height: 100vh;
            overflow:hidden;

            img{
                width: 100%;
            }
        }
        
    }

    .industry-hero__button{
        padding-left: 10rem;
        position: relative;
        transform: translateY(50%);

        .ind-button{
            padding-left: 30px;
        }

        .svg-plus-2{
            width: 150px;
            position: absolute;
            right: 10%;

            svg{
                width: 100%; 
                fill: #135CFD;
            }
        }
    }
}

@media only screen and (max-width: 480px){
    .industry-hero .industry-hero__header{
        padding-left: 20px;
    }

    .industry-hero .industry-hero__header .industry-hero__header-title h1{
        font-size: 4rem;
    }

    .industry-hero .industry-hero__body{
        padding-left: 20px;
    }

    .industry-hero .industry-hero__button{
        padding-left: 20px;
        padding-right: 20px;
    }
    .industry-hero__body-content{
        padding-top: 2rem;
        padding-right: 20px;
    }
    .industry-hero .industry-hero__header-caption{
        margin-bottom: 2rem;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px){
    .industry-hero .industry-hero__header{
        padding-left: 20px;
    }

    .industry-hero .industry-hero__header .industry-hero__header-title h1{
        font-size: 4rem;
    }

    .industry-hero .industry-hero__body{
        padding-left: 20px;
    }

    .industry-hero .industry-hero__button{
        padding-left: 20px;
        padding-right: 20px;
    }
    .industry-hero__body-content{
        padding-top: 2rem;
        padding-right: 20px;
    }
    .industry-hero .industry-hero__header-caption{
        margin-bottom: 2rem;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1200px){
    .industry-hero .industry-hero__header{
        padding-left: 20px;
    }

    .industry-hero .industry-hero__header .industry-hero__header-title h1{
        font-size: 7rem;
    }

    .industry-hero .industry-hero__body{
        padding-left: 20px;
    }

    .industry-hero .industry-hero__button{
        padding-left: 20px;
    }

     .industry-hero .industry-hero__header-caption{
        margin-bottom: 2rem;
    }

    .industry-hero__body-content{
        margin-top: 1rem;
    }

}

@media only screen and (min-width: 1201px) and (max-width: 1400px){

}