.solutions-hero{
    height: 100vh;
    margin-bottom: 6.666667rem;

    .solutions-wrapper{
        height: 100%;
        display: flex;
    }

    .solutions-hero__title{
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 50%;
        justify-content: center;
        padding-left: 10rem;
       

        h1{
            font-size: 9.33333rem;
            line-height: 0.9;
            margin-bottom: 3.33333rem;
            text-transform: uppercase;
        }
    }

    .solutions-hero__image{
        width: 50%;
        height: 100%;
        background-image: url('https://iti.ca/themes/iti/assets/images/static/solutions-human_bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }
}

@media only screen and (max-width: 480px){
    .solutions-hero .solutions-hero__title{
        padding-left: 20px;
    }

    .solutions-hero .solutions-wrapper{
        flex-direction: column;
    }

    .solutions-hero .solutions-hero__title{
        width: 100%;

        h1{
            font-size: 4rem;
            margin-bottom: 2rem;
        }
    }

    .solutions-hero .solutions-hero__image{
        width: 100%;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px){
    .solutions-hero .solutions-hero__title{
        padding-left: 20px;
    }

    .solutions-hero .solutions-wrapper{
        flex-direction: column;
    }

    .solutions-hero .solutions-hero__title{
        width: 100%;

        h1{
            font-size: 4rem;
            margin-bottom: 2rem;
        }
    }

    .solutions-hero .solutions-hero__image{
        width: 100%;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1200px){
    .solutions-hero{
        height: 120vh;
    }
    .solutions-hero .solutions-hero__title{
        padding-left: 20px;
    }

    .solutions-hero .solutions-wrapper{
        flex-direction: column;
    }

    .solutions-hero .solutions-hero__title{
        width: 100%;

        h1{
            font-size: 6.6666rem;
            margin-bottom: 2rem;
        }
    }

    .solutions-hero .solutions-hero__image{
        width: 100%;
    }
}