.quote{
    margin-bottom: 6.666667rem;

    .quote-wapper{
        display: flex;

        .blockquote-wrapper{
            position: relative;
            width: 30%;

            #quote{
                width: 150px;
                fill: #3CEDB7;
                position: absolute;
                right: 17%;
            }
        }

        .mainquote-wrapper{
            max-width: 70%;
            padding-right: 10rem;

            h2{
                font-style: italic;
            }
        }
    }
}

@media only screen and (max-width: 480px){
    .quote .quote-wapper .mainquote-wrapper{
        padding-right: 0px;
    }

    .quote .quote-wapper .blockquote-wrapper{
        width: 0px;
    }

    .quote .quote-wapper .mainquote-wrapper{
        max-width: 100%;
    }

    
}   

@media only screen and (min-width: 481px) and (max-width: 768px){
    .quote .quote-wapper .mainquote-wrapper{
        padding-right: 0px;
    }

    .quote .quote-wapper .blockquote-wrapper{
        width: 0px;
    }

    .quote .quote-wapper .mainquote-wrapper{
        max-width: 100%;
    }

}

@media only screen and (min-width: 769px) and (max-width: 1200px){
    .quote .quote-wapper .mainquote-wrapper{
        padding-right: 0px;
    }

    .quote .quote-wapper .blockquote-wrapper{
        width: 20%;
    }

    .quote .quote-wapper .mainquote-wrapper{
        max-width: 80%;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px){
    .quote .quote-wapper .mainquote-wrapper{
        padding-right: 0px;
    }
}