.main-header{
    padding: 0 2.66667rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.66667rem;
    background: #fff;
    z-index: 8;
    border-bottom: 1px solid #d4e0e6;

    .logo{
        width: 80px;

        img{
            width: 100%;
        }
    }

    nav{
        display: flex;
        height: 100%;
        .nav-cta{
            display: flex;
            align-items: center;
            .talk{
                position: relative;
                background-color: #0F2043!important;
                color: white!important;
                box-shadow: none;
                border: none;
                border-radius: 7px;
                padding: 15px 1.33333rem!important;
                width: 12rem;
                .cta-text{
                    position: relative;
                    color: white;
                    z-index: 5;
                }
    
                .cta-icon{
                    color: white;
                }

                &::before{
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    transform: scaleX(0);
                    transition: transform .6s cubic-bezier(.075,.82,.165,1);
                    transform-origin: center left;
                    border-radius: 7px;
                    background-color: #38E2B1;
                }
            }

            .talk:hover::before{
                transform: scaleX(1);
            }             
        }
    }

    .nav-links{
        display: flex;
        margin-left: 0px!important;
        padding-left: 0px!important;
        margin-bottom: 0px!important;

        li{
            list-style: none;
            padding-left: 25px;
            padding-right: 50px;
            display: flex;
            align-items: center;
            

            a{
                color: #221e1e;
            }

            p{
                margin-bottom: 0rem!important;
                font-weight: 600;
                cursor: pointer;
            }
            
            .dropdown-menu{
                position: absolute;
                left: 0;
                top: -30vh;
                display: flex;
                flex-direction: column;
                width: 100%;
                min-height: 30vh;
                z-index: -1;
                border: 0px;

                .drop-links{
                    position: absolute;
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    justify-content: end;
                    padding-bottom: 20px;
                   
                    .drop-link{
                         opacity: 0;

                         a{
                             text-decoration: none;
                             padding-left: 1.33333rem;
                             font-size: 1.6rem;
                             color: #221e1e;
                             font-weight: 600;
                         }
                    }
                }
            }
        }
    }
    
    .btn-nav-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        display: none;
    
        .btn-nav{
            border: none;
            background-color: transparent;
            position: relative;

        }
        span{
            display: flex;
            width: 50px;
            height: 1px;
            background-color: #000;
            margin-top: 6px;
        }
    }

    
}

.responsive-nav{
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 100px 1.33rem 0px 1.33rem;
    background-color: white;
    z-index: 6;
    display: flex;
    flex-direction: column;

    .resp-nav-wrapper{
        opacity: 0;
    }

    ul{
        padding-left: 0px;
        li{
            list-style: none;
            a{
                text-decoration: none;
                color: #221e1e;
                font-size: 1.6666rem;
                font-weight: 600;
            }
        }
    }

    .industries-wrapper{
        border-top: 1px solid #dddada;
        padding-top: 15px;

        p{
            font-size: 1.6666rem;
            font-weight: 600;
        }

        .ind-list-wrapper{
            display: flex;

            .width-50{
                width: 50%;

                p{
                    font-size: 0.9333rem;
                    font-weight: 400;
                }
            }
        }

    }

    .contact-info{
        border-top: 1px solid #dddada;
        padding-top: 15px;

        p{
            font-size: 1.66666rem;
            font-weight: 600;
        }

        ul{
            li{
                p{
                    font-size: .9rem;
                    margin-bottom: 0px;
                }

                margin-bottom: 25px;

                .color-blue{
                    color: #135cfd;;
                }
            }
        }
    }
}


// RESPONSIVENESS

@media only screen and (max-width: 480px){
    .main-header{
        padding: 0 1.333rem;
    }

    .main-header nav{
        display: none;
    }

    .main-header .btn-nav-wrapper{
        display: block;
    }
    
    
}

@media only screen and (min-width: 481px) and (max-width: 768px){
    .main-header{
        padding: 0 1.333rem;
    }

    .main-header nav{
        display: none;
    }

    .main-header .btn-nav-wrapper{
        display: block;
    }

    .responsive-nav ul li a, .responsive-nav .industries-wrapper p, .responsive-nav .contact-info p{
        font-size: 2rem;
    }

    .responsive-nav .industries-wrapper .ind-list-wrapper .width-50 p, .responsive-nav .contact-info ul li p, .responsive-nav .contact-info ul li p{
        font-size: 1.2rem;
    }

}

@media only screen and (min-width: 769px) and (max-width: 1024px){
    .main-header{
        padding: 0 1.333rem;
    }

    .main-header nav{
        display: none;
    }

    .main-header .btn-nav-wrapper{
        display: block;
    }

    .responsive-nav ul li a, .responsive-nav .industries-wrapper p, .responsive-nav .contact-info p{
        font-size: 2rem;
    }

    .responsive-nav .industries-wrapper .ind-list-wrapper .width-50 p, .responsive-nav .contact-info ul li p, .responsive-nav .contact-info ul li p{
        font-size: 1.2rem;
    }

}

@media only screen and (min-width: 1025px) and (max-width: 1200px){

}

@media only screen and (min-width: 1201px) and (max-width: 1400px){

}


