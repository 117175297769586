#dragger-clients{
    background-color: #f6f9fb;
    margin-bottom: 9.333333rem;

    .dragger-container{
        padding-left: 10rem;
        padding-right: 10rem;
        padding-top: 0px;
        overflow: hidden;
        margin-top: 0;
        position: relative;

        .client-card-wrapper{
            padding-bottom: 100px;
            
            p{
                pointer-events: none;
            }

            img{
                pointer-events: none;
            }
        }
    }
    
}

#dragger-clients .dragger-container .client-card-wrapper img{
    width: 100%;
}

#dragger-clients .carousel-button-group{
    top: 0%;
}

@media only screen and (max-width: 480px) {
    #dragger-clients .dragger-container{
        padding-left: 20px;
        padding-right: 20px;
    }

    #dragger-clients{
        padding-bottom: 0px;
        margin-bottom: 6.66rem;
    }

    #dragger-clients .dragger-container .client-card-wrapper{
        padding-bottom: 0px;
        
    }

    #dragger-clients .carousel-button-group{
        top: 95%;
    }
    #dragger-clients .dragger-container .client-card-wrapper img{
        width: 100%;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px){
    #dragger-clients .dragger-container{
        padding-left: 20px;
        padding-right: 20px;
    }

    #dragger-clients{
        padding-bottom: 0px;
        margin-bottom: 6.66rem;
    }

    #dragger-clients .dragger-container .client-card-wrapper{
        padding-bottom: 0px;
        
    }

    #dragger-clients .carousel-button-group{
        top: 95%;
    }
    #dragger-clients .dragger-container .client-card-wrapper img{
        width: 100%;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px){
    #dragger-clients .dragger-container{
        padding-left: 20px;
        padding-right: 20px;
    }

    #dragger-clients .carousel-button-group{
        top: 95%;
    }

}