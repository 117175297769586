.experts{
    margin-bottom: 6.66666rem;
    .experts-wrapper{
        width: 80%;
        margin: auto;
        background: #e1eff6;
        padding: 6.66667rem 0;
        text-align: center;
    }
    
    .nav-cta{
        justify-content: center;
    }

    .ex-caption{
        margin-bottom: 2.666667rem;
    }

    .ex-title{
        margin-bottom: 2.666667rem;
        font-size: 4rem; 
    }

    .ex-sent{
        margin-bottom: 2.666667rem;
    }
}

@media only screen and (max-width: 480px){
    .experts .experts-wrapper{
        width: 100%;
    }
    
}

@media only screen and (min-width: 481px) and (max-width: 768px){
    .experts .experts-wrapper{
        width: 100%;
    }
    
}

@media only screen and (min-width: 769px) and (max-width: 1200px){
    
}

@media only screen and (min-width: 1201px) and (max-width: 1400px){

}