.para-content{
    background-color: #F6F9FB;
    margin-bottom: 9.33333rem;
}
.para-content__wrapper{
    padding-left: 10rem;
    padding-right: 10rem;
    .para-content__title{
        margin-bottom: 6.666667rem;

        h3{
            color: #221e1e;
            font-size: 2rem;
            font-weight: 400;
            max-width: 50%;
        }
    }

    .para-content__content{
        max-width: 70%;
        margin-left: auto;
        padding-right: 10rem;
       

        p{
            font-size: 1rem;
        }
    }
}

@media only screen and (max-width: 480px){
    .para-content__wrapper{
        padding-left: 20px;
        padding-right: 20px;
    }

    .para-content__wrapper .para-content__title h3{
        max-width: 90%;
    }

    .para-content__wrapper .para-content__content{
        max-width: 100%;
        padding-right: 20px;
    }

    .para-content__wrapper .para-content__title{
        margin-bottom: 4rem;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px){
    .para-content__wrapper{
        padding-left: 20px;
        padding-right: 20px;
    }

    .para-content__wrapper .para-content__title h3{
        max-width: 80%;
    }

    .para-content__wrapper .para-content__content{
        max-width: 80%;
        padding-right: 20px;
    }

    .para-content__wrapper .para-content__title{
        margin-bottom: 4rem;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1200px){
    .para-content__wrapper{
        padding-left: 20px;
        padding-right: 20px;
    }

    .para-content__wrapper .para-content__title h3{
        max-width: 80%;
    }

    .para-content__wrapper .para-content__content{
        max-width: 80%;
        padding-right: 20px;
    }

    .para-content__wrapper .para-content__title{
        margin-bottom: 4rem;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px){
    .para-content__wrapper .para-content__content{
        padding-right: 0px;
    }

    
}