.random-img{
    .img-wrapper{
        // padding: 0 20rem;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 6.6667rem;
        img{
            width: 100%;
        }
    }
}


@media only screen and (max-width: 480px){
    .random-img .img-wrapper{
        width: 100%;
    }
}


@media only screen and (min-width: 481px) and (max-width: 768px){
    .random-img .img-wrapper{
        width: 100%;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1200px){
    .random-img .img-wrapper{
        width: 100%;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px){

}