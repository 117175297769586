.cls-1{fill:#091f42;}
.cls-1,
.cls-2,
.cls-3{fill-rule:evenodd;}
.cls-2{fill:#205b2d;}
.cls-3{fill:#052043;}

.about-hero{
    height: 100vh;
    overflow: hidden;
    position: relative;

    svg{
        margin: auto;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        
    }

    .svg-wrapper{
        width: 100%;
        height: 100%;
        h1{
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            font-size: 9vw;
            color: #000;

            span{
                animation: animate-p 5s ease infinite;
            }
        }
    }
}

@keyframes animate-p {
    0%{
        color: #96E072;
    }

    20%{
        color: #9C89B8;
    }

    40%{
        color: #FF3562
    }

    60%{
        color: #DDFC74;
    }

    80%{
        color: #BF6900;
    }

    100%{
        color: #94A89A;
    }
}